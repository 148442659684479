import { ContactOptionType } from "@/types/Contact.type";

export const ContactOptions: Array<ContactOptionType> = [
  {
    name: "me@gauravsachdeva.in",
    icon: "mail",
    link: "mailto:me@gauravsachdeva.in",
  },
  {
    name: "Telegram",
    icon: "telegram",
    link: "https://t.me/iamarav",
  },
  {
    name: "Skype @arav248",
    icon: "skype",
    link: "#skype=arav248",
  },
];

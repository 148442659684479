
import { defineComponent } from "vue";
// import SocialLinks from "../components/SocialLinks.vue";
import { ContactOptions } from "../config/contact";

export default defineComponent({
  // components: { SocialLinks },
  data() {
    return {
      ContactOptions,
    };
  },
});
